import React from "react"
import Layout from "@components/layout"
import { graphql, Link } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import SEO from "@components/seo"
import marked from "marked"
import rocket from "@images/rocketLaunch.jpg"
import placeHolderImg from "@images/placeholderBG.jpg"
// import "@components/blog.css"

const BlogTemplate = ({ data, location }) => {
  const { post, posts } = data

  // console.log("singleposts", post)
  return (
    <Layout location={location}>
      <SEO title={post.title} description={post.excerpt} />
      <div
        style={{
          background: `linear-gradient( 225deg, rgba(95, 20, 105, 0.5046393557422969) 0%, rgba(84, 38, 253, 0.5) 100% ), url(${
            post &&
            post.mainImage &&
            post.mainImage.asset &&
            post.mainImage.asset.fluid &&
            post.mainImage.asset.fluid.src
              ? post.mainImage.asset.fluid.src
              : placeHolderImg
          })`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container
          className="headline-section"
          style={{
            paddingTop: "150px",
            paddingBottom: "150px",
          }}
        >
          <div className="row py-5">
            <div className="col-lg-7">
              <div className="inner-blog text-white">
                <h1 className="text-white mb-3">
                  <strong>{post.title}</strong>
                </h1>

                <p
                  style={{
                    fontSize: "18px",
                    paddingRight: "25px",
                    fontWeight: "bold",
                  }}
                  className="mt-5 text-white"
                >
                  {post.excerpt}
                </p>

                <div className="author-section d-inline">
                  {post.authors && post.authors.length !== 0 ? (
                    <div className="d-inline">
                      <img
                        src={post.authors[0].person.image.asset.fluid.src}
                        className="img-fluid rounded-circle float-left"
                        alt={post.authors[0].person.name}
                      />
                      <h6
                        className="d-inline"
                        style={{
                          padding: "11px 20px",
                          float: "left",
                          borderRight: "3px solid #fff",
                        }}
                      >
                        {post.authors[0].person.name}
                      </h6>
                    </div>
                  ) : null}
                  <h6
                    className="d-inline"
                    style={{
                      padding: "11px 20px",
                      float: "left",
                    }}
                  >
                    {post.publishedAt}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div
        style={{ background: "#fff" }}
        className="container-fluid py-5 p-relative"
      >
        <Container>
          <Row className="position-relative" style={{ zIndex: 4 }}>
            <Col lg={7} md={8} className="offset-1 pr-5">
              {post.body ? (
                <div dangerouslySetInnerHTML={{ __html: marked(post.body) }} />
              ) : null}
              <div className="mt-5">
                <div className="author-section " style={{ height: 65 }}>
                  {post.authors && post.authors.length !== 0 ? (
                    <div className="d-inline">
                      <img
                        src={post.authors[0].person.image.asset.fluid.src}
                        className="img-fluid rounded-circle float-left"
                        alt={post.authors[0].person.name}
                      />
                      <h6
                        className="d-inline"
                        style={{
                          padding: "11px 20px",
                          float: "left",
                          borderRight: "3px solid #747474",
                        }}
                      >
                        {post.authors[0].person.name}
                      </h6>
                    </div>
                  ) : null}
                  <h6
                    className="d-inline"
                    style={{
                      padding: "11px 20px",
                      float: "left",
                      borderRight: "3px solid #747474",
                    }}
                  >
                    {post.publishedAt}
                  </h6>
                  {post.authors &&
                  post.authors.length !== 0 &&
                  post.authors[0].person.linkedInURL ? (
                    <div className="d-inline">
                      <a
                        href={post.authors[0].person.linkedInURL}
                        style={{
                          background: "#747474",
                          borderRadius: "50%",
                          color: "#fff",
                          marginLeft: "20px",
                          padding: "6px 9px",
                          verticalAlign: "sub",
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i class="fa fa-linkedin" aria-hidden="true"></i>
                      </a>
                    </div>
                  ) : null}
                </div>
                <div className="d-block">{post.authors[0].person.bio}</div>
              </div>
            </Col>
            <Col lg={3} md={4}>
              <div className="pl-3">
                <h3 className="mb-5">Recent Blogs</h3>
                {posts.edges.map(latest => (
                  <div
                    className="latest-blog"
                    key={latest.node.id}
                    style={{
                      paddingBottom: "10px",
                      marginBottom: "40px",
                      borderBottom: "1px solid #ccc",
                    }}
                  >
                    <Link
                      to={latest.node.slug.current}
                      style={{ color: "#5F1469" }}
                    >
                      {latest.node.title}
                    </Link>

                    {latest.node.publishedAt ? (
                      <p className="mt-2" style={{ verticalAlign: "top" }}>
                        {" "}
                        <i
                          className="fa fa-calendar text-leadblue mr-2"
                          style={{ color: "#ccc", fontSize: 29 }}
                        ></i>
                        <span style={{ verticalAlign: "inherit" }}>
                          {latest.node.publishedAt}
                        </span>
                      </p>
                    ) : null}
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        style={{
          backgroundImage: `url(${rocket})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "140px 0",
        }}
        className="text-center text-white "
      >
        <Container>
          <Row>
            <Col className="mx-auto" lg={8} md={8}>
              <h3 style={{ fontSize: 40 }}>
                Transform Your LinkedIn Profile Into A Lead Generating Machine
              </h3>
              <p>
                Schedule a strategy call with our team and we'll help see if a
                LinkedIn outreach strategy will work in your niche.
              </p>
              <div className="d-flex flex-column">
                <div>
                  <Link to="/" className="btn btn-primary btn-lg btn-red">
                    BOOK A STRATEGY CALL
                  </Link>
                </div>
                <Link to="/faq" className="mt-4 check-faq-link">
                  Or Check Out Our FAQ Section
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default BlogTemplate

export const blogSingle = graphql`
  query blogSingle($id: String!) {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    post: sanityPost(id: { eq: $id }) {
      id
      mainImage {
        asset {
          fluid {
            src
          }
        }
      }
      excerpt
      publishedAt(formatString: "MMMM DD, YYYY")
      body
      title
      authors {
        person {
          id
          bio
          linkedInURL
          name
          image {
            asset {
              fluid(maxWidth: 48) {
                src
              }
            }
          }
        }
      }
    }
    posts: allSanityPost(
      filter: { slug: { current: { ne: null } } }
      sort: { fields: publishedAt, order: DESC }
      limit: 7
    ) {
      edges {
        node {
          id
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          slug {
            current
          }
        }
      }
    }
  }
`
